<script lang="ts" setup>
const { t } = useT();
const isGuest = useIsGuest();
const { isMobile } = useDevice();
const { data: appInitData } = useAppInitData();
const { pageData, seoData, filteredBanners, refresh } = useHomePage({ immediate: !isGuest.value });
refresh();
usePromotionsSocket();

const isD0User = computed(() => appInitData.value?.depositsCount === 0);

useHead({
	title: () => seoData.value?.seoTitle || "",
	meta: [
		{ name: "description", content: () => seoData.value?.seoDescription },
		{ name: "keywords", content: () => seoData.value?.seoKeywords }
	],
	bodyAttrs: {
		class: "home-page"
	}
});
</script>
<template>
	<div class="main">
		<template v-if="!isGuest && !isD0User">
			<AText as="h1" variant="taiz" :modifiers="['semibold']">
				{{ t("Select your perfect coin bundle!") }}
			</AText>

			<LazyOHomeQuickCash />
		</template>

		<template v-else>
			<AText :modifiers="['bold', 'center']" as="h1" variant="taiz">
				{{ t("Leading Social Casino") }}
			</AText>
			<AText class="subtitle text-conakry" :modifiers="['bold', 'center']" as="p" variant="tanta">
				<i18n-t keypath="{key1} 100% {key2}">
					<template #key1>
						<span>{{ $t("Enjoy") }}</span>
					</template>
					<template #key2>
						<span class="text-chimoio">{{ $t("Free Games") }}</span>
					</template>
				</i18n-t>
			</AText>

			<LazyOHomeBanners v-if="filteredBanners && filteredBanners.length" :banners="filteredBanners" />
		</template>
		<LazyOHomeSectionGames :itemSection="0" class="section" />
		<LazyOHomeActivities />
		<LazyOHomeSectionGames :itemSection="1" />

		<LazyOHomeActivityFeed />
		<LazyOHomeSectionGames :itemSection="2" />
		<OLazyComponent
			v-if="!isGuest"
			id="promotions"
			:height="257"
			:width="348"
			:items-count="isMobile ? 2 : 5"
			gap="20px"
		>
			<template #skeleton-title>
				<MSkeletonTitle />
			</template>
			<LazyOHomePromotions />
		</OLazyComponent>
		<LazyOHomeSectionGames :itemSection="3" />

		<OLazyComponent id="jackpots" :height="150">
			<LazyOHomeJackpot :jackpots="pageData?.jackpots" />
		</OLazyComponent>
		<LazyOHomeSectionGames :itemSection="4" />
	</div>
</template>
<style lang="scss" scoped>
.main {
	color: var(--cannes);
	display: flex;
	flex-direction: column;

	.section {
		margin-bottom: 16px;
	}

	@include media-breakpoint-down(md) {
		h1 {
			display: none;
		}

		.subtitle {
			font-size: 14px;
		}
	}

	.text-chimoio {
		color: var(--chimoio);
	}

	.subtitle {
		margin-top: gutter(0.5);

		span:first-of-type {
			font-weight: 400;
		}
	}

	.banners,
	.quick-cash,
	.jackpot {
		margin: gutter(3) 0;

		@include media-breakpoint-down(md) {
			margin: gutter(2.5) 0;
		}
	}

	&:deep(.skeleton-wrapper) {
		--a-skeleton-background-color: var(--a-button-navigation-border-color);

		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	&:deep(.lazy-skeleton) {
		margin: 24px 0;

		@include media-breakpoint-down(md) {
			margin: 20px 0;
		}
	}

	.lazy-skeleton {
		margin: 24px 0;

		@include media-breakpoint-down(md) {
			margin: 20px 0;
		}
	}
}
</style>
